import {
  useState,
  useImperativeHandle,
  useRef,
  forwardRef,
  useCallback,
} from "react";
import { StepBody } from "../../../Stepper/Stepper";
import styles from "./MergeSchoolsStep1.module.scss";
import { Role } from "../../../../types/Role";
import RestrictedTo from "../../../RestrictedTo/RestrictedTo";
import { Form, Formik, FormikProps } from "formik";
import { Country } from "../../../../types/Country";
import { Grid, GridItem } from "../../../Grid/Grid";
import { MultiSelect } from "../../../MultiSelect/MultiSelect";
import { TextInput } from "../../../TextInput/TextInput";
import { Button } from "../../../Button/Button";
import { Table } from "../../../Table/Table";
import { SelectColumn, Column, SortColumn } from "react-data-grid";
import Pagination from "../../../Paginate/Pagination";
import { StatusLinkWithSalesForce } from "../../../../types/Salesforce";
import {
  AdminSearchSchoolResponse,
  SearchSchoolsCondition,
  adminSearchSchools,
} from "../../../../api/school";
import { DataWithPagination } from "../../../../types/User";
import { isEmpty } from "lodash";
import { NoRecordFoundModal } from "../../../UserManagement/NoRecordFoundModal/NoRecordFoundModal";
import { Spinner } from "../../../Spinner/Spinner";
import { ErrorModal } from "../../ErrorModal/ErrorModal";

const columns: readonly Column<Row>[] = [
  {
    ...SelectColumn,
  },
  {
    key: "id",
    name: "ID",
    width: 100,
  },
  {
    key: "name",
    name: "School Name",
    width: 300,
  },
  {
    key: "status",
    name: "Status",
    width: 150,
  },
  {
    key: "country",
    name: "Country",
    width: 100,
  },
  {
    key: "address",
    name: "Address",
    width: 200,
  },
  {
    key: "addressAddition",
    name: "Address Addition",
    width: 200,
  },
  {
    key: "city",
    name: "City",
    width: 200,
  },
  {
    key: "state",
    name: "State",
    width: 200,
  },
  {
    key: "zip",
    name: "Zip Code",
    width: 200,
  },
  {
    key: "schoolId",
    name: "schoolID",
    width: 200,
  },
  {
    key: "salesforceId",
    name: "Salesforce ID",
    width: 200,
  },
  {
    key: "lmsInstance",
    name: "LMS Instance",
    width: 200,
  },
  {
    key: "eduplacesSchoolId",
    name: "Eduplaces School ID",
    width: 200,
  },
  {
    key: "salesforceRecordId",
    name: "Salesforce Record ID",
    width: 200,
  },
  {
    key: "type",
    name: "Type",
    width: 200,
  },
  {
    key: "addressCountry",
    name: "Address Country",
    width: 200,
  },
];

interface Row {
  id: string;
  name: string;
  status: StatusLinkWithSalesForce;
  country: Country;
  address: string | undefined;
  addressAddition: string | undefined;
  city: string;
  state: string;
  zip: string;
  schoolId: string;
  salesforceId: string | undefined;
  lmsInstance: string | undefined;
  eduplacesSchoolId: string | undefined;
  salesforceRecordId: string | undefined;
  type: string | undefined;
  addressCountry: string | undefined;
}

export interface SelectedSchoolStep1 extends Row {}
export interface MergeSchoolsStep1Props {
  handleNext?(): void;
  getSelectedSchool?(selectedSchool: Row): void;
}
interface FormikValues {
  country: { value: Country; label: string; data: Country }[];
  state: string;
  city: string;
  name: string;
  eduPlacesLmsInstance: string;
  zip: string;
  userId: string;
  address: string;
  addressAddition: string;
  eduplacesSchoolId: string;
  salesForceSchoolIdLocalOne: string;
  salesForceSchoolIdLocalTwo: string;
  salesForceRecordId: string;
  type: string;
  addressCountry: string;
  status: {
    value: StatusLinkWithSalesForce;
    label: string;
    data: StatusLinkWithSalesForce;
  }[];
}

interface SortOptions {
  page: number;
  limit: number;
  sortBy: string;
  sortOrder: "asc" | "desc";
}

function rowKeyGetter(row: Row): any {
  return row.id;
}

const initialValues: FormikValues = {
  country: [],
  state: "",
  city: "",
  name: "",
  eduPlacesLmsInstance: "",
  zip: "",
  userId: "",
  address: "",
  addressAddition: "",
  eduplacesSchoolId: "",
  salesForceSchoolIdLocalOne: "",
  salesForceSchoolIdLocalTwo: "",
  salesForceRecordId: "",
  type: "",
  addressCountry: "",
  status: [],
};

export interface AdminSearchSchoolsResponse
  extends DataWithPagination<AdminSearchSchoolResponse> {}

export const MergeSchoolsStep1 = forwardRef(
  (props: MergeSchoolsStep1Props, ref) => {
    const { handleNext, getSelectedSchool } = props;

    const [rows, setRows] = useState<Row[]>([]);

    const [selectedRows, setSelectedRows] = useState(
      (): ReadonlySet<string> => new Set()
    );
    const [totalSchoolsCount, setTotalSchoolsCount] = useState<number>(0);
    const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);

    const [sortOptions, setSortOptions] = useState<SortOptions>({
      page: 1,
      limit: 10,
      sortBy: "name",
      sortOrder: "asc",
    });

    const [searchCondition, setSearchCondition] =
      useState<SearchSchoolsCondition>({});

    const [forcePage, setForcePage] = useState<number>();

    const [selectedSchool, setSelectedSchool] = useState<Row>();
    console.log("selectedSchool", selectedSchool);

    const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false);
    const [isShowNoRecordFoundModal, setIsShowNoRecordFoundModal] =
      useState<boolean>(false);
    const [isShowErrorModal, setIsShowErrorModal] = useState<boolean>(false);

    const formikRef = useRef<FormikProps<FormikValues>>(null);

    const setRowsFromSchoolsData = useCallback(
      (schools: AdminSearchSchoolResponse[]) => {
        let rows: Row[] = [];

        for (let data of schools) {
          rows.push({
            id: data.id,
            name: data.name,
            status: data.status,
            country: data.country,
            address: data.address ? data.address : "",
            addressAddition: data.addressAddition ? data.addressAddition : "",
            city: data.city,
            state: data.state ? data.state : "",
            zip: data.zip ? data.zip : "",
            schoolId: data.schoolId,
            salesforceId: data.salesforceId,
            eduplacesSchoolId: data.eduplacesSchoolId,
            salesforceRecordId: data.salesforceRecordId,
            type: data.type,
            addressCountry: data.addressCountry,
            lmsInstance: data.lmsInstance,
          });
        }

        setRows(rows);
      },
      []
    );

    const fetchSchools = useCallback(
      async (
        sortOptions: SortOptions,
        searchCondition: SearchSchoolsCondition
      ) => {
        setIsShowSpinner(true);

        const schools: AdminSearchSchoolsResponse | undefined =
          await adminSearchSchools(sortOptions, searchCondition, false);

        if (schools?.paginateData && schools.paginateData.length > 0) {
          setRowsFromSchoolsData(schools.paginateData);
          setTotalSchoolsCount(schools.totalCount);
        }

        setIsShowSpinner(false);
      },
      [setRowsFromSchoolsData]
    );

    const onSortColumns = useCallback(
      (sortColumns: SortColumn[], searchCondition: SearchSchoolsCondition) => {
        let sortOptionsUpdate = { ...sortOptions };

        if (!isEmpty(sortColumns)) {
          sortOptionsUpdate.sortBy = sortColumns[0].columnKey;
          sortOptionsUpdate.sortOrder =
            sortColumns[0].direction.toLowerCase() as "asc" | "desc";
        }
        setSortOptions(sortOptionsUpdate);

        fetchSchools(sortOptionsUpdate, searchCondition);
        setSortColumns(sortColumns);
      },
      [fetchSchools, sortOptions]
    );

    useImperativeHandle(ref, () => ({
      // This method will be exposed by useImperativeHandle and can access to it via ref.
      resetForm() {
        formikRef.current?.resetForm(initialValues);
      },
      resetTableData() {
        setRows([]);
      },
    }));

    return (
      <StepBody
        noBackgroundColor={true}
        nextButton={
          <Button
            label={"Merge"}
            onClick={handleNext}
            disabled={!selectedSchool && !isShowErrorModal}
          />
        }
      >
        <RestrictedTo roles={[Role.ADMINISTRATOR]} redirects={true}>
          <div className={styles.mergeSchoolsStep1Container}>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validate={(values: FormikValues) => {}}
              onSubmit={async (values: FormikValues) => {
                try {
                  setIsShowSpinner(true);
                  const searchSchoolsParams: SearchSchoolsCondition = {
                    country: values.country ? values.country : undefined,
                    state: values.state ? values.state.trim() : undefined,
                    city: values.city ? values.city.trim() : undefined,
                    schoolName: values.name ? values.name.trim() : undefined,
                    lmsInstance: values.eduPlacesLmsInstance
                      ? values.eduPlacesLmsInstance.trim()
                      : undefined,
                    zip: values.zip ? values.zip.trim() : undefined,
                    id: values.userId ? values.userId.trim() : undefined,
                    address: values.address ? values.address.trim() : undefined,
                    addressAddition: values.addressAddition
                      ? values.addressAddition.trim()
                      : undefined,
                    eduplacesSchoolId: values.eduplacesSchoolId
                      ? values.eduplacesSchoolId.trim()
                      : undefined,
                    schoolId: values.salesForceSchoolIdLocalOne
                      ? values.salesForceSchoolIdLocalOne.trim()
                      : undefined,
                    salesforceId: values.salesForceSchoolIdLocalTwo
                      ? values.salesForceSchoolIdLocalTwo.trim()
                      : undefined,
                    salesforceRecordId: values.salesForceRecordId
                      ? values.salesForceRecordId.trim()
                      : undefined,
                    type: values.type ? values.type.trim() : undefined,
                    addressCountry: values.addressCountry
                      ? values.addressCountry.trim()
                      : undefined,
                    status: values.status ? values.status : undefined,
                  };

                  const sortOptionsUpdate = { ...sortOptions };
                  sortOptionsUpdate.page = 1;

                  const searchedSchools: DataWithPagination<AdminSearchSchoolResponse> =
                    await adminSearchSchools(
                      sortOptionsUpdate,
                      searchSchoolsParams,
                      false
                    );

                  if (
                    searchedSchools?.paginateData &&
                    searchedSchools.paginateData.length === 0
                  ) {
                    setIsShowNoRecordFoundModal(true);
                  }

                  setRowsFromSchoolsData(searchedSchools.paginateData);
                  setTotalSchoolsCount(searchedSchools.totalCount);

                  setSearchCondition(searchSchoolsParams);

                  // This condition to force component Pagination re-render.
                  if (forcePage === 0) {
                    setForcePage(undefined);
                  }
                  if (!forcePage) {
                    setForcePage(0);
                  }

                  setSelectedSchool(undefined);
                  setSelectedRows(new Set());
                } catch (err) {
                  setIsShowSpinner(false);
                  console.log("err onSubmit", err);
                } finally {
                  setIsShowSpinner(false);
                }
              }}
              innerRef={formikRef}
            >
              {({ handleChange, values }) => (
                <Form>
                  <div
                    className={
                      styles.mergeSchoolsStep1Container_searchFieldsContainer
                    }
                  >
                    <Grid>
                      <GridItem width="1/3">
                        <MultiSelect
                          id="country"
                          options={[
                            {
                              value: Country.DE,
                              label: "DE",
                              data: Country.DE,
                            },
                            {
                              value: Country.FR,
                              label: "FR",
                              data: Country.FR,
                            },
                          ]}
                          label="Country"
                          value={values.country}
                        />
                      </GridItem>
                      <GridItem width="1/3">
                        <TextInput
                          id="state"
                          label="State"
                          onChange={handleChange}
                          value={values.state}
                        />
                      </GridItem>

                      <GridItem width="1/3">
                        <TextInput
                          id="city"
                          label="City"
                          onChange={handleChange}
                          value={values.city}
                        />
                      </GridItem>
                    </Grid>

                    {/* ----- */}

                    <Grid>
                      <GridItem width="1/3">
                        <TextInput
                          id="name"
                          label="School name"
                          onChange={handleChange}
                          value={values.name}
                        />
                      </GridItem>
                      <GridItem width="1/3">
                        <TextInput
                          id="eduPlacesLmsInstance"
                          label="LMS Instance (LMS Identifier)"
                          onChange={handleChange}
                          value={values.eduPlacesLmsInstance}
                        />
                      </GridItem>

                      <GridItem width="1/3">
                        <TextInput
                          id="zip"
                          label="Zip Code"
                          onChange={handleChange}
                          value={values.zip}
                        />
                      </GridItem>
                    </Grid>

                    {/* ----- */}

                    <Grid>
                      <GridItem width="1/3">
                        <TextInput
                          id="userId"
                          label="ID"
                          onChange={handleChange}
                          value={values.userId}
                        />
                      </GridItem>

                      <GridItem width="1/3">
                        <TextInput
                          id="address"
                          label="Address"
                          onChange={handleChange}
                          value={values.address}
                        />
                      </GridItem>

                      <GridItem width="1/3">
                        <TextInput
                          id="addressAddition"
                          label="Address Addition"
                          onChange={handleChange}
                          value={values.addressAddition}
                        />
                      </GridItem>
                    </Grid>

                    {/* ----- */}
                    <Grid>
                      <GridItem width="1/3">
                        <TextInput
                          id="eduplacesSchoolId"
                          label="Eduplaces School ID"
                          onChange={handleChange}
                          value={values.eduplacesSchoolId}
                        />
                      </GridItem>

                      <GridItem width="1/3">
                        <TextInput
                          id="salesForceSchoolIdLocalOne"
                          label="schoolID (Salesforce School ID Local 1)"
                          onChange={handleChange}
                          value={values.salesForceSchoolIdLocalOne}
                        />
                      </GridItem>

                      <GridItem width="1/3">
                        <TextInput
                          id="salesForceSchoolIdLocalTwo"
                          label="Salesforce ID (Salesforce School ID Local 2)"
                          onChange={handleChange}
                          value={values.salesForceSchoolIdLocalTwo}
                        />
                      </GridItem>
                    </Grid>

                    {/* ----- */}
                    <Grid>
                      <GridItem width="1/3">
                        <TextInput
                          id="salesForceRecordId"
                          label="Salesforce Record ID"
                          onChange={handleChange}
                          value={values.salesForceRecordId}
                        />
                      </GridItem>

                      <GridItem width="1/3">
                        <TextInput
                          id="type"
                          label="Type (Salesforce Curriculum of your school)"
                          onChange={handleChange}
                          value={values.type}
                        />
                      </GridItem>

                      <GridItem width="1/3">
                        <TextInput
                          id="addressCountry"
                          label="Address Country (Salesforce Country Abroad)"
                          onChange={handleChange}
                          value={values.addressCountry}
                        />
                      </GridItem>
                    </Grid>

                    <Grid>
                      <GridItem width="1/3">
                        <MultiSelect
                          id="status"
                          options={[
                            {
                              value: StatusLinkWithSalesForce.LINKEDSF,
                              label: "Linked SF",
                              data: StatusLinkWithSalesForce.LINKEDSF,
                            },
                            {
                              value: StatusLinkWithSalesForce.NOTLINKEDSF,
                              label: "Not Linked SF",
                              data: StatusLinkWithSalesForce.NOTLINKEDSF,
                            },
                          ]}
                          label="Status (Link with Salesforce)"
                          value={values.status}
                        />
                      </GridItem>
                    </Grid>
                  </div>
                  <div className={styles.mergeSchoolsStep1Container__btnGroup}>
                    <Button
                      btnStyle="secondary"
                      label="clear"
                      onClick={() => {
                        formikRef.current?.resetForm(initialValues);
                      }}
                    ></Button>
                    <Button type="submit" label="search"></Button>
                  </div>
                </Form>
              )}
            </Formik>

            <div className={styles.mergeSchoolsStep1Container__tableContainer}>
              <Table
                isOnlyOneRowCanBeSelected={true}
                columns={columns}
                rows={rows}
                onRowsChange={setRows}
                sortColumns={sortColumns}
                onSortColumnsChange={(sortColumns) =>
                  onSortColumns(sortColumns, searchCondition)
                }
                rowKeyGetter={rowKeyGetter}
                selectedRows={selectedRows}
                onSelectedRowsChange={(selectedRow, selectedData: Row[]) => {
                  setSelectedRows(selectedRow);

                  const selectedSchool = selectedData[0];
                  if (selectedSchool && selectedSchool.salesforceRecordId) {
                    // show error message popup
                    setIsShowErrorModal(true);
                    return;
                  }

                  setSelectedSchool(selectedSchool);
                  if (getSelectedSchool) {
                    getSelectedSchool(selectedSchool);
                  }
                }}
              />
            </div>

            {rows.length > 0 && (
              <Pagination
                items={rows}
                totalCount={totalSchoolsCount}
                forcePage={forcePage}
                getItemsPerPage={async (itemsPerPage: number) => {
                  // limit
                  let sortOptionsUpdate = { ...sortOptions };

                  sortOptionsUpdate.limit = itemsPerPage;
                  sortOptionsUpdate.page = 1;
                  setSortOptions(sortOptionsUpdate);

                  await fetchSchools(sortOptionsUpdate, searchCondition);

                  if (forcePage === 0) {
                    setForcePage(undefined);
                  }
                  if (!forcePage) {
                    setForcePage(0);
                  }
                }}
                getPageNumber={async (pageNumber: number) => {
                  // page
                  let sortOptionsUpdate = { ...sortOptions };

                  sortOptionsUpdate.page = pageNumber;
                  setSortOptions(sortOptionsUpdate);

                  await fetchSchools(sortOptionsUpdate, searchCondition);
                }}
              />
            )}
          </div>
        </RestrictedTo>

        {isShowNoRecordFoundModal && (
          <NoRecordFoundModal
            isShowing={isShowNoRecordFoundModal}
            setVisible={setIsShowNoRecordFoundModal}
          />
        )}

        <ErrorModal
          isShowing={isShowErrorModal}
          setVisible={(value) => {
            setIsShowErrorModal(value);
            setSelectedSchool(undefined);
          }}
          title="Error"
          text="CPA (Salesforce) School record cannot be merged. Please select a
          manually created school record or an Eduplaces school record."
        />

        <Spinner
          type="fullContainer"
          title="Please wait..."
          visible={isShowSpinner}
        />
      </StepBody>
    );
  }
);
