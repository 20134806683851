import axios from "axios";
import { AddSchoolFormSchema } from "../components/AddSchool/AddSchool";
import { Country } from "../types/Country";
import { StatusLinkWithSalesForce } from "../types/Salesforce";
import { buildSearchParams } from "../utils/utils";
import { DataWithPagination } from "../types/User";

const url = "internal/v2/schools";
const adminUrl = "internal/v1/admin/schools";

export interface School {
  id: string;
  name: string;
  address: string;
  addressAddition: string;
  zip: string;
  city: string;
  addressCountry: string;
  country: Country;
  type: string;
  schoolId: string;
  salesforceId: string;
}

export interface SearchUsersData {
  country?: Country[];
  state?: string;
  city?: string;
  schoolName?: string;
  lmsInstance?: string;
  zip?: string;
  id?: string;
  address?: string;
  addressAddition?: string;
  eduplacesSchoolId?: string;
  schoolId?: string;
  salesforceId?: string;
  salesforceRecordId?: string;
  type?: string;
  addressCountry?: string;
  status?: StatusLinkWithSalesForce[];
}

export interface AdminSearchSchoolResponse {
  id: string;
  name: string;
  address: string | undefined;
  addressAddition: string | undefined;
  zip: string | undefined;
  city: string;
  addressCountry: string | undefined;
  country: Country;
  type: string | undefined;
  schoolId: string;
  salesforceId: string;
  lmsInstance: string | undefined;
  state: string | undefined;
  eduplacesSchoolId: string | undefined;
  salesforceRecordId: string | undefined;
  status: StatusLinkWithSalesForce;
}

export interface MergedSchoolResponse extends AdminSearchSchoolResponse {}

export interface SearchSchoolsCondition
  extends Omit<SearchUsersData, "country" | "status"> {
  country?: { value: Country; label: string; data: Country }[];
  status?: {
    value: StatusLinkWithSalesForce;
    label: string;
    data: StatusLinkWithSalesForce;
  }[];
}

export enum SchoolType {
  MANUALLY_CREATED = "ManuallyCreated",
  FROM_EDUPLACES = "FromEduplaces",
}

interface UnmergeSchoolData extends MergedSchoolResponse {}
export interface UnmergeSchoolReponse {
  eduplacesSchool: UnmergeSchoolData;
  cpaSchool: UnmergeSchoolData;
}

export const getSchool = async (
  id: string,
  apiBaseUrl = "/app/api"
): Promise<School> => {
  try {
    const response = await axios.get<School>(`${apiBaseUrl}/${url}/${id}`);
    return response.data;
  } catch (err) {
    throw new Error("school.get.error");
  }
};

export const querySchools = async (
  queryString: string,
  country: string = "de",
  apiBaseUrl = "/app/api"
): Promise<School[]> => {
  try {
    const response = await axios.get<School[]>(
      `${apiBaseUrl}/${url}/query/${country}?q=${encodeURIComponent(
        queryString
      )}`
    );
    return response.data;
  } catch (err) {
    throw new Error("school.query.error");
  }
};

export const querySchoolsWithNoCountryFilter = async (
  queryString: string,
  apiBaseUrl = "/app/api"
): Promise<School[]> => {
  try {
    const response = await axios.get<School[]>(
      `${apiBaseUrl}/${url}/no-country/query?q=${encodeURIComponent(
        queryString
      )}`
    );
    return response.data;
  } catch (err) {
    throw new Error("school.query.error");
  }
};

export const addSchool = async (
  school: AddSchoolFormSchema,
  apiBaseUrl = "/app/api"
): Promise<string> => {
  const response = await axios.post<string>(`${apiBaseUrl}/${url}/`, {
    name: school.schoolname,
    address: school.schoolstreet,
    addressAddition: school.schooladdressaddition,
    zip: school.schoolzip,
    city: school.schoolcity,
    addressCountry: school.schooladdresscountry,
    type: school.schooltype,
    country: school.schoolcountry,
    schoolId: school.schoolcode,
  });
  return response.data;
};

export const adminSearchSchools = async (
  {
    page,
    limit,
    sortOrder,
    sortBy,
  }: {
    page: number;
    limit: number;
    sortOrder?: "asc" | "desc";
    sortBy?: string;
  },
  search: SearchSchoolsCondition,
  unmerge: boolean,
  apiBaseUrl = "/app/api"
): Promise<DataWithPagination<AdminSearchSchoolResponse>> => {
  try {
    if (!page) {
      throw new Error("page is required");
    }

    if (!limit) {
      throw new Error("limit is required");
    }

    let queryUrl = `${apiBaseUrl}/${adminUrl}/search?page=${page}&limit=${limit}`;

    if (unmerge) {
      queryUrl += `&unmerge=true`;
    }

    if (sortOrder) {
      queryUrl += `&sortOrder=${sortOrder}`;
    }

    if (sortBy) {
      queryUrl += `&sortBy=${sortBy}`;
    }

    let searchCondition: SearchUsersData = {};

    if (search.country && search.country.length > 0) {
      searchCondition.country = search.country.map((item) => item.data);
    }

    if (search.state) {
      searchCondition.state = search.state;
    }

    if (search.city) {
      searchCondition.city = search.city;
    }

    if (search.schoolName) {
      searchCondition.schoolName = search.schoolName;
    }

    if (search.lmsInstance) {
      searchCondition.lmsInstance = search.lmsInstance;
    }

    if (search.zip) {
      searchCondition.zip = search.zip;
    }

    if (search.id) {
      searchCondition.id = search.id;
    }

    if (search.address) {
      searchCondition.address = search.address;
    }

    if (search.addressAddition) {
      searchCondition.addressAddition = search.addressAddition;
    }

    if (search.eduplacesSchoolId) {
      searchCondition.eduplacesSchoolId = search.eduplacesSchoolId;
    }

    if (search.schoolId) {
      searchCondition.schoolId = search.schoolId;
    }

    if (search.salesforceId) {
      searchCondition.salesforceId = search.salesforceId;
    }

    if (search.salesforceRecordId) {
      searchCondition.salesforceRecordId = search.salesforceRecordId;
    }

    if (search.type) {
      searchCondition.type = search.type;
    }

    if (search.addressCountry) {
      searchCondition.addressCountry = search.addressCountry;
    }

    if (search.status && search.status.length > 0) {
      searchCondition.status = search.status.map((item) => item.data);
    }

    const searchParams = buildSearchParams(searchCondition);
    const endpoint = queryUrl + searchParams;

    const schools = await axios.get<
      DataWithPagination<AdminSearchSchoolResponse>
    >(endpoint);

    return schools.data;
  } catch (err) {
    console.error(`Error in adminSeachSchools: ${err}`);
    throw new Error(`get.user.with.pagination.and.sort.error: ${err}`);
  }
};

export const mergeSchools = async (
  {
    mergingSchoolId,
    mergingSchoolType,
    cpaSchoolId,
  }: {
    mergingSchoolId: string;
    mergingSchoolType: SchoolType;
    cpaSchoolId: string;
  },
  apiBaseUrl = "/app/api"
): Promise<MergedSchoolResponse> => {
  try {
    const response = await axios.post<MergedSchoolResponse>(
      `${apiBaseUrl}/${adminUrl}/merge-schools`,
      {
        mergingSchoolId,
        mergingSchoolType,
        cpaSchoolId,
      }
    );

    return response.data;
  } catch (err) {
    console.error("err.merge.schools", err);
    throw err;
  }
};

export const unMergeSchools = async (
  unmergedSchoolRecordId: string,
  apiBaseUrl = "/app/api"
): Promise<UnmergeSchoolReponse> => {
  try {
    const response = await axios.post<UnmergeSchoolReponse>(
      `${apiBaseUrl}/${adminUrl}/unmerge-schools`,
      {
        unmergedSchoolRecordId,
      }
    );

    return response.data;
  } catch (err) {
    console.error("err.unmerge.schools", err);
    throw err;
  }
};
