import React from "react";
import { ArrowRight, Link as LinkIcon } from "react-feather";
import { Link } from "react-router-dom";
import { ReactComponent as IservLogo } from "../../../assets/images/svg/iserv-logo-color-neutral.svg";
import { ReactComponent as EduplacesLogo } from "../../../assets/images/svg/eduplaces_logo.svg";
import garLogo from "../../../assets/images/gar-image.png";
import { classList } from "../../../lib/helpers";
import { Color } from "../../../types/Color";
import { Context } from "../../../types/Context";
import { IdProvider } from "../../../types/IdProvider";
import styles from "./ContextList.module.scss";

export interface ContextListProps {
  contexts: Context[];
}

export const ContextList: React.FC<ContextListProps> = ({ contexts }) => {
  return (
    <ul className={styles.contextList}>
      {contexts.map((context) => (
        <li key={context.id}>
          <Link to={`details/${context.id}`}>
            <ContextListItem
              color={context.color}
              name={context.name}
              idProvider={context.origin}
            />
          </Link>
        </li>
      ))}
    </ul>
  );
};

export interface ContextListItemProps {
  color: Color;
  name: string;
  idProvider: IdProvider;
}

export const ContextListItem: React.FC<ContextListItemProps> = ({
  color,
  name,
  idProvider,
}) => {
  const colorIndicatorClasses = classList(
    styles.contextListItem__colorIndicator,
    styles["contextListItem__colorIndicator_" + color.toLowerCase()]
  );
  return (
    <div className={styles.contextListItem}>
      <span role="img" className={colorIndicatorClasses}></span>
      <div className={styles.contextListItem__left}>
        {idProvider !== IdProvider.CLASSPAD && (
          <div className={styles.contextListItem__external}>
            <LinkIcon />
            {idProvider === IdProvider.ISERV && <IservLogo />}
            {idProvider === IdProvider.GAR && (
              <div className={styles.contextListItem__gar_logo}>
                <img src={garLogo} alt="gar-logo" />
                <span>&nbsp;GAR</span>
              </div>
            )}
            {idProvider === IdProvider.EDUPLACES && (
              <div className={styles.contextListItem__eduplaces_logo}>
                <EduplacesLogo />
              </div>
            )}
          </div>
        )}
        <span>{name}</span>
      </div>
      <i role="img">
        <ArrowRight />
      </i>
    </div>
  );
};
