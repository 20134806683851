import { useCallback, useEffect, useState } from "react";
import { Role } from "../../types/Role";
import { Card } from "../Card/Card";
import { PageHeader } from "../PageHeader/PageHeader";
import RestrictedTo from "../RestrictedTo/RestrictedTo";
import styles from "./UploadStudentUser.module.scss";
import { useDropzone } from "react-dropzone";
import { Button } from "../Button/Button";
import { submitUsersCsv } from "../../api/user";
import { useToasts } from "../../provider/toast-provider";
import { UploadStudentUserReview } from "./UploadStudentUserReview";
import { Spinner } from "../Spinner/Spinner";
import { i18n } from "@lingui/core";
import Papa from "papaparse";
import { CSVFileErrorModal } from "./CSVFileErrorModal/CSVFileErrorModal";
import { useAuth } from "../../hooks/use-auth";
import { Language } from "../../types/Country";
import { Context } from "../../types/Context";

export interface SubmitUserResponse {
  errors: string[];
  data: UserCsvImport[];
}

export interface UserCsvImport {
  row?: number;
  firstname: string;
  lastname: string;
  email?: string;
  classes?: Context[];
  contextName?: string[];
  contextCode?: string[];
}

export const UploadStudentUser = () => {
  const auth = useAuth();
  const { addToast } = useToasts();
  const [file, setFile] = useState<any>(null);
  const [uploadedDownloadLink, setUploadedDownloadLink] = useState<string>("");
  const [csvLines, setCsvLines] = useState<number>(0);
  const onDrop = useCallback((acceptedFile: any) => {
    const csvFile = acceptedFile[0];
    setFile(csvFile);
    Papa.parse(csvFile, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setCsvLines(results.data.length);
      },
    });

    const data = new Blob([csvFile], { type: "text/csv" });
    const csvURL = window.URL.createObjectURL(data);
    setUploadedDownloadLink(csvURL);
  }, []);
  const [errors, setErrors] = useState<string[]>([]);
  const [data, setData] = useState<UserCsvImport[]>([]);
  const [isCreatedUser, setIsCreatedUser] = useState<boolean>(false);
  const [isShowReviewScreen, setIsShowReviewScreen] = useState<boolean>(false);
  const [isShowCSVLineMoreThanLimitModal, setIsShowCSVLineMoreThanLimitModal] =
    useState<boolean>(false);
  const [isShowCSVLineNoRecordModal, setIsShowCSVLineNoRecordModal] =
    useState<boolean>(false);
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false);

  useEffect(() => {
    setFile(null);
    setIsCreatedUser(false);
  }, [isCreatedUser]);

  const onSubmit = async () => {
    if (csvLines > 100) {
      setIsShowCSVLineMoreThanLimitModal(true);
    } else if (csvLines <= 0) {
      setIsShowCSVLineNoRecordModal(true);
    } else {
      try {
        setIsShowSpinner(true);
        await submitUsersCsv(file, auth.user?.language as Language).then(
          (response) => {
            setErrors(response.errors);
            setData(response.data);
            setIsShowReviewScreen(true);
          }
        );
      } catch (err: any) {
        addToast(err.message ? err.message : err, "error", 5000);
      } finally {
        setIsShowSpinner(false);
      }
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    multiple: false,
    accept: {
      "text/csv": [".csv"],
    },
  });

  return (
    <RestrictedTo roles={[Role.INSTRUCTOR]} redirects={true}>
      {!isShowReviewScreen && (
        <div className={styles.container}>
          <PageHeader
            title={i18n._({ id: "student_list.upload.screen.header" })}
          />
          <Card title={i18n._({ id: "student_list.upload.screen.title" })}>
            {auth.user?.language === Language.fr && (
              <div className={styles.description}>
                <span>
                  {i18n._({ id: "student_list.upload.screen.description1" })}
                </span>
                <span>
                  {i18n._({ id: "student_list.upload.screen.description2" })}
                </span>
                <span>
                  {i18n._({ id: "student_list.upload.screen.description3" })}
                </span>
                <span>
                  {i18n._({ id: "student_list.upload.screen.description4" })}
                </span>
                <span>
                  {i18n._({ id: "student_list.upload.screen.description5" })}
                </span>
                <span>
                  {i18n._({ id: "student_list.upload.screen.description6" })}{" "}
                  <a
                    className={styles.exampleFile}
                    href="./example.csv"
                    download
                  >
                    example.csv
                  </a>
                </span>
              </div>
            )}
            {auth.user?.language === Language.de && (
              <div className={styles.description}>
                <span>
                  <em>
                    {i18n._({ id: "student_list.upload.screen.description1" })}
                  </em>
                </span>

                <br />
                <span>
                  <em>
                    {i18n._({ id: "student_list.upload.screen.description6" })}{" "}
                    <a
                      className={styles.exampleFile}
                      href="./example.csv"
                      download
                    >
                      example.csv
                    </a>
                  </em>
                </span>
                <br />

                <span>
                  {i18n._({ id: "student_list.upload.screen.description2" })}
                </span>
                <span>
                  {i18n._({ id: "student_list.upload.screen.description3" })}
                </span>
                <span>
                  {i18n._({ id: "student_list.upload.screen.description4" })}
                </span>
              </div>
            )}
          </Card>
          <Card>
            <div {...getRootProps()} className={styles.fileUploader}>
              <input {...getInputProps()} />
              {!file ? (
                <>
                  <p>
                    {i18n._({ id: "student_list.upload.screen.drag_drop" })}
                  </p>
                  <p>{i18n._({ id: "student_list.upload.screen.or" })}</p>
                </>
              ) : (
                <a
                  download={file.name}
                  href={uploadedDownloadLink}
                  className={styles.fileUploaded}
                >
                  {file?.name}
                </a>
              )}
              <Button
                label={i18n._({
                  id: "student_list.upload.screen.btn.browse_file",
                })}
                onClick={open}
              ></Button>
            </div>
          </Card>
          <Card>
            <div className={styles.description}>
              <span>
                {i18n._({ id: "student_list.upload.screen.description7" })}
              </span>
              <span>
                {i18n._({ id: "student_list.upload.screen.description8" })}
              </span>
            </div>
          </Card>

          <Button
            className={styles.submitBtn}
            label={i18n._({ id: "student_list.upload.screen.btn.submit" })}
            type="submit"
            disabled={!file}
            onClick={onSubmit}
          ></Button>
        </div>
      )}
      {isShowReviewScreen && (
        <UploadStudentUserReview
          errors={errors}
          data={data}
          setVisible={setIsShowReviewScreen}
          setCreatedUser={setIsCreatedUser}
        />
      )}
      {isShowCSVLineMoreThanLimitModal && (
        <CSVFileErrorModal
          isShowing={isShowCSVLineMoreThanLimitModal}
          setVisible={setIsShowCSVLineMoreThanLimitModal}
          description={i18n._({
            id: "student_list.upload.csv.error.modal.context.limit",
          })}
        ></CSVFileErrorModal>
      )}
      {isShowCSVLineNoRecordModal && (
        <CSVFileErrorModal
          isShowing={isShowCSVLineNoRecordModal}
          setVisible={setIsShowCSVLineNoRecordModal}
          description={i18n._({
            id: "student_list.upload.csv.error.modal.context.no_record",
          })}
        ></CSVFileErrorModal>
      )}
      <Spinner
        type="fullPage"
        title={i18n._({ id: "loading" })}
        visible={isShowSpinner}
      />
    </RestrictedTo>
  );
};
